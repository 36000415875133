export * from './messages'
export const roleOptions = [
  {
    value: 'admin',
    label: 'Administrador',
  },
  {
    value: 'attendant',
    label: 'Atendente',
  },
]

export const viewContactsOptions = [
  {
    value: 'all',
    label: 'Todos os contatos',
  },
  {
    value: 'himself',
    label: 'Os próprios contatos',
  },
]

export const allowMobileOptions = [
  {
    value: true,
    label: 'Sim',
  },
  {
    value: false,
    label: 'Não',
  },
]

export const bussinessDayOptions = [
  { label: 'Segunda', value: 'monday' },
  { label: 'Terça', value: 'tuesday' },
  { label: 'Quarta', value: 'wednesday' },
  { label: 'Quinta', value: 'thursday' },
  { label: 'Sexta', value: 'friday' },
  { label: 'Sábado', value: 'saturday' },
  { label: 'Domingo', value: 'sunday' },
]

export const uraOptions = [
  { label: 'Sequencial sem Bot', value: 'sequential_without_bot' },
  { label: 'Sequencial com Bot', value: 'sequential_with_bot' },
  { label: 'Pesca sem Bot', value: 'fishing_without_bot' },
  // { label: 'Direcionamento Exclusivo', value: 'exclusive_targeting' }
]

export const endChatAfterOptions = [
  { label: '10 minutos', value: 10 },
  { label: '20 minutos', value: 20 },
  { label: '30 minutos', value: 30 },
  { label: '60 minutos', value: 60 },
  { label: '120 minutos', value: 120 },
]

export const satisfactionSurveyForceOptions = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
]

export const MessageStatus = {
  ERROR: 0,
  PENDING: 1,
  SENT: 2,
  ON_DEVICE: 3,
  READ: 4,
  PLAYED: 5,
  RECEIVED: 6,
}

export const Regex = {
  empty: /^\s*$/,
  email: /[^\s@]+@[^\s@]+\.[^\s@]+/,
}

export const validatorOptions = [
  { label: '', value: null },
  { label: 'CPF/CNPJ', value: 'document' },
  { label: 'CPF', value: 'document_cpf' },
  { label: 'CNPJ', value: 'document_cnpj' },
  { label: 'E-mail', value: 'email' },
]

export const pickerTranslate = {
  categories: {
    recent: 'Recentes',
    smileys: 'Sorrisos e Pessoas',
    people: 'Pessoas',
    nature: 'Animais e Natureza',
    foods: 'Comida e Bebida',
    activity: 'Atividades',
    places: 'Viagem e Lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
  },
}

export const ScheduledMessagesStatus: Record<string, string | undefined> = {
  sent: 'Enviado',
  scheduled: 'Agendado',
}

export const periodOptions = [
  {
    value: '15days',
    label: '15 dias',
  },
  {
    value: '30days',
    label: '30 dias',
  },
  {
    value: '60days',
    label: '60 dias',
  },
  {
    value: '90days',
    label: '90 dias',
  },
  {
    value: '120days',
    label: '120 dias',
  },
  {
    value: 'personalized',
    label: 'Personalizado',
  },
]
