import { createId } from '@paralleldrive/cuid2'
import { defineStore } from 'pinia'

export const useBotSetting = defineStore('bot-setting', {
  state: () => ({
    fetching: false,
    wrongLevels: [] as string[],
    wrongLevelsIds: [] as any[],
    levelSettingModalOpened: false,
    currentLevelSetting: {} as IBotNestedList,
    currentNestedList: [] as IBotNestedList[],
  }),
  getters: {
    fetchState: (state) => state.fetching,
    levelSetting: (state) => state.currentLevelSetting,
    nestedList: (state) => state.currentNestedList,
  },
  actions: {
    async fetchBotSettings() {
      this.fetching = true
      try {
        if (!Object.keys(this.currentNestedList).length) {
          const request = await window.axios.get(
            route('dashboard.chatbot.listJson')
          )
          this.currentNestedList = request.data
        }
      } catch (e: any) {
        throw new Error(e)
      } finally {
        this.fetching = false
      }
    },
    setLevelSetting(element: IBotNestedList) {
      this.levelSettingModalOpened = true
      this.currentLevelSetting = element
    },
    closeLevelSettingModal() {
      this.levelSettingModalOpened = false
      if (this.currentLevelSetting.action?.entity_id === 0) {
        this.currentLevelSetting.action = {
          mode: null,
        }
      }
      this.currentLevelSetting = {} as IBotNestedList
    },
    addNewList(title: string, element?: IBotNestedList) {
      const object = {
        id: createId(),
        title,
        action: {
          mode: null,
        },
        children: [],
      }

      if (element) {
        if (element.action.mode !== null) {
          element.action = {
            mode: null,
            entity_id: undefined,
          }
        }
        element.children?.unshift(object)
      } else {
        this.currentNestedList.unshift(object)
      }
    },
    hasWrongLevels() {
      this.wrongLevels = []
      this.wrongLevelsIds = []
      this.verifyChildrens()

      if (this.wrongLevels.length) {
        return this.wrongLevels
      } else {
        return false
      }
    },
    verifyChildrens(el?: IBotNestedList[]) {
      const element = el ?? this.currentNestedList

      element.forEach((item) => {
        if (item?.children?.length) {
          this.verifyChildrens(item.children)
        } else if (item.action.mode === null) {
          this.wrongLevelsIds.push(item.id)
          this.wrongLevels.push(item.title)
        }
      })
    },
    removeListNode(arr: IBotNestedList[], id: string | number) {
      const index = arr.findIndex((obj) => obj.id === id)
      if (index !== -1) {
        arr.splice(index, 1)
      } else {
        for (const obj of arr) {
          if (obj.children) {
            this.removeListNode(obj.children, id)
          }
        }
      }
    },
  },
  persist: {
    storage: sessionStorage,
  },
})
