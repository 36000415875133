import type Conversation from '../Conversation'

export class PublishMessageEvent {
  message!: IPChat.PartialMessage
  conversation!: Conversation

  constructor(props: {
    message: IPChat.PartialMessage
    conversation: Conversation
  }) {
    const { message, conversation } = props

    this.message = {
      ...message,
      quoted_message:
        message.quoted_message && message.quoted_message?.key
          ? {
            key: message.quoted_message.key,
          }
          : undefined,
    }
    this.conversation = conversation
  }

  sendText() {
    window.queue.push(async () => {
      const request = await window.axios
        .post(
          route('v2.conversation.sendText', { state: this.conversation.stateId }),
          {
            message: this.message,
          },
          {
            'axios-retry': {
              retries: 0,
            }
          }
        )

      window.dispatchEvent(new Event('MessageSent'))
      return request
    })
  }

  sendFile(props: {
    media: File
    routeAlias?: string
    refOfMessage?: IPChat.PartialMessage
    messageKey?: string
  }) {
    if (!props?.routeAlias) {
      props.routeAlias = 'v2.conversation.sendFile'
    }

    const content = _.cloneDeep(this.message)

    delete content.message.media
    window.queue.push(async () => {
      const form = new FormData()

      form.append('content', JSON.stringify(content))
      form.append('file', props.media)

      const request = await window.axios
        .post(
          route(String(props.routeAlias), { state: this.conversation.stateId }),
          form,
          {
            'axios-retry': {
              retries: 0,
            },
          }
        )

      if (props.refOfMessage) {
        props.refOfMessage.status = 2
      }

      window.dispatchEvent(new Event('MessageSent'))
      return request
    })


    /**
     * Em caso de erro lançar evento
     * Caso o contato atual não seja mais o da mensagem em si
     * Lançar uma notificação com o $q.notify
     * caso contrário apenas mostrar na tela a manesagem com erro
     */
  }
}
