import { messages } from '@/Constants'
import { defineStore } from 'pinia'
import { Dialog, Notify } from 'quasar'
import _ from 'lodash'
import logger from '@/Helpers/Logger'

type ContactView = IPChat.ContactView | 'unreadMessages' | 'filterByAttendant' | 'group'
export const useContactStore = defineStore('contact-store', {
  state: () => ({
    manifest: [] as unknown as IPChat.Contact[],
    searchTerm: '',
    filter: null as {
      attendantId?: number
      filterStatusBy: IPChat.StateStatus
    } | null,
    view: 'active' as ContactView,
    isLoading: false,
    total: 0,
    loadedCount: 0,
  }),
  getters: {
    filterEnabled: (state) => state.view !== 'active',
    searchEnabled: (state) => state.view === 'searchResult',
    hasContact: (state) => {
      return (id: number) => state.manifest.some((contact) => contact.id === id)
    },
    getContact: (state) => {
      return (id: number) => state.manifest.find((contact) => contact.id === id)
    },
    loadingState: (state) => {
      if (state.total === 0) return 0
      let loaded = (state.loadedCount / state.total) * 100
      loaded = Math.round(loaded * 100) / 100

      return loaded
    },
  },
  actions: {
    filterByAttendant(props: {
      attendantId: number
      filterBy: IPChat.StateStatus
    }) {
      this.filter = {
        attendantId: props.attendantId,
        filterStatusBy: props.filterBy,
      }

      const contactStore = useContactStore()
      contactStore.view = 'filterByAttendant'

      const modalsStore = useModalsStore()
      modalsStore.toggleFilterByAttendantModal()
    },
    updateContact(props: { contactId: number; name: string; number: string }) {
      const contact = this.getContact(props.contactId)

      if (contact) {
        contact.name = props.name
        contact.number = props.number
      }
    },
    addNewContact(props: { event: IPChat.Socket.NewContact }) {
      const { contact } = props.event.key
      const exists = this.manifest.find((el) => el.id === contact.id)

      if (!exists) {
        this.manifest.push(contact)
      }
    },
    contactEdited(props: { event: IPChat.Socket.ContactEdited }) {
      const { event } = props
      const contact = this.getContact(event.key.contact.id)

      if (contact) {
        _.assignIn(contact, props.event.key.contact)
      } else {
        this.addNewContact({
          event: {
            key: {
              contact: event.key.contact,
            },
          },
        })
      }
    },
    contactDeleted(props: {contactId: number}) {
      _.remove(this.manifest, (contact) => contact.id === props.contactId)
    },
    deleteContact(props: { contactId: number }) {
      const activeChatStore = useActiveChatStore()

      Dialog.create({
        title: 'Tem certeza que deseja deletar esse contato?',
        message:
          'Após essa ação ser iniciada não poderá ser desfeita, tudo que estiver relacionado ao contato será excluído.',
        persistent: true,
        ok: {
          label: 'Deletar contato',
          color: 'red',
          flat: true,
        },
        cancel: {
          label: 'Cancelar',
          flat: true,
        },
        focus: 'none',
      }).onOk(() => {
        window.axios
          .delete(
            route('chat.contact.destroyContact', { contact: props.contactId }),
            {
              'axios-retry': {
                retries: 0,
              },
            }
          )
          .then(() => {
            Notify.create({
              type: 'success',
              message: messages.DELETE_CONTACT.SUCCESS,
            })

            if (
              props.contactId ===
              activeChatStore.currentContact.props.contact.id
            ) {
              activeChatStore.clear()
            }
          })
          .catch((error) => {
            Notify.create({
              type: 'error',
              message:
                error.response.data.error ?? messages.DELETE_CONTACT.ERROR,
            })
          })
      })
    },
    setContactView(props: { view: IPChat.ContactView }) {
      const { view } = props
      this.view = view
    },
    clearFilter() {
      this.view = 'active'
      this.searchTerm = ''
      this.filter = null

      const conversationStore = useConversationsStore()
      conversationStore.clearSearchResult()
    },
    async fetchOne(props: { contactId: number }) {
      const { contactId } = props

      try {
        const request = await window.axios.get<IPChat.Contact>(
          route('v2.contact.fetchOne', { _query: { contactId } })
        )
        this.manifest.push(request.data)
      } catch (e) {
        //
      }
    },
    async getPendingsForGroup() {
      const stateStore = useStateStore()
      const manifest = stateStore.manifest

      const groupIds = manifest.filter((el) => el.is_group).map((element) => element.id)
      const groupIdsChunked = _.chunk(groupIds, 300)

      for (const ids of groupIdsChunked) {

        const { data: pendings } = await useGroupPendings({ stateIds: ids })

        for (const pending of pendings) {
          const state = stateStore.getState(pending.id)
          if (state) {
            state.pending_to_read = pending.was_read === 0
          }
        }

      }
    },
    async syncManifestV2() {
      if (!this.isLoading) {
        this.isLoading = true
      }

      const stateStore = useStateStore()
      const manifest = stateStore.manifest

      const contactIds = manifest.map((element) => element.contact_id)
      const contactIdsChunked = _.chunk(contactIds, 1000)

      if (this.total === 0) {
        this.total = contactIds.length
      }

      const set = new Set<IPChat.Contact>()

      for (const ids of contactIdsChunked) {

        const { data: contacts } = await useContactManifest({ contactIds: ids })

        for (const contact of contacts) {

          if (!this.hasContact(contact.id)) {

            if (contact.is_group && contact?.restricted) {
              const auth = useAuthStore()
              if (!contact.resticted_to_attendants?.includes(auth.user.id)) {
                continue
              }
            }

            set.add(contact)
            this.loadedCount++
          }
        }

      }

      this.manifest = Array.from(set)

      this.getPendingsForGroup()

      this.isLoading = false

      await waitFor(0.250)
    },
    async syncManifest(props?: { page: number }) {
      if (!props) {
        props = {
          page: 1,
        }
      }

      if (!this.isLoading) {
        this.isLoading = true
      }

      const manifest = await window.axios.get<{
        data: IPChat.Contact[]
        total: number
        current_page: number
        last_page: number
      }>(
        route('v2.contact.generateManifest', {
          _query: { page: props.page, limit: 300 },
        })
      )

      if (this.total === 0) {
        this.total = manifest.data.total
      }
      const requestCurrentPage = manifest.data.current_page
      const requestLastPage = manifest.data.last_page

      for (const contact of manifest.data.data) {
        if (!this.hasContact(contact.id)) {
          if (contact.is_group && contact?.restricted) {
            const auth = useAuthStore()
            if (!contact.resticted_to_attendants?.includes(auth.user.id)) {
              continue
            }
          }

          this.manifest.push(contact)
        }
      }

      if (requestLastPage > 1 && requestCurrentPage < requestLastPage) {
        await this.syncManifest({
          page: props.page + 1,
        })
      } else {
        this.isLoading = false
      }
    },
    async searchContact(props: { term: string }, abortController?: AbortController) {
      const { term: search } = props

      if (_.isEmpty(search)) {
        this.clearFilter()
        this.view = 'active'
        return
      }

      this.view = 'searchResult'
      /**
       * Verifica se existe conversation
       * Se existir conversation adiciona a lista de searchResult
       *
       * Se não existir, cria o objeto conversation e adiciona na lista de searchResult
       */
      try {
        const { data: searchResult } = await window.axios.get<IPChat.SearchContactResult[]>(
          route('v2.contact.search', { _query: { search } }),
          { signal: abortController?.signal, 'axios-retry': { retries: 0 } }
        )

        const conversationStore = useConversationsStore()
        conversationStore.handleSearch({ results: searchResult })
      } catch (e: any) {

        if ('code' in e && e.code === 'ERR_CANCELED') {
          return
        }

        const message = 'Houve um erro ao efetuar a busca, tente novamente.'
        Notify.create({
          type: 'error',
          message,
        })

        logger.error(e, message)
      }
    },
    async getContactsWithBot() {
      this.view = 'searchResult'

      try {
        const { data: searchResult } = await window.axios.get<
          IPChat.SearchContactResult[]
        >(route('v2.contact.withBot'))

        const conversationStore = useConversationsStore()
        conversationStore.handleSearch({ results: searchResult })
      } catch (e) {
        Notify.create({
          type: 'error',
          message: 'Houve um erro ao efetuar o filtro, tente novamente.',
        })

        console.error(e)
      }
    },
    async filterByTag(props: { tagId: number }) {
      const { tagId } = props

      this.view = 'searchResult'

      const conversationStore = useConversationsStore()
      conversationStore.handleFilterByTag({ tagId })
    },
  },
})
