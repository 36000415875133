export enum MessageType {
  TEXT = 'App\\Core\\Entities\\MessageText',
  IMAGE = 'App\\Core\\Entities\\MessageImage',
  STICKER = 'App\\Core\\Entities\\MessageSticker',
  VIDEO = 'App\\Core\\Entities\\MessageVideo',
  AUDIO = 'App\\Core\\Entities\\MessageAudio',
  DOCUMENT = 'App\\Core\\Entities\\MessageDocument',
  CONTACT = 'App\\Core\\Entities\\ContactMessage',
  LOCATION = 'App\\Core\\Entities\\LocationMessage',
}
