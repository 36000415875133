import pino from 'pino'

const logger = pino({
  browser: {
    serialize: true,
    asObject: true,
    transmit: undefined,
  },
})

export default logger
