<script setup lang="ts">
defineEmits([ ...useDialogPluginComponent.emits ])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent()
</script>

<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card>
      <q-card-section>
        <div class="text-h6">Você tem certeza?</div>
        <div>A mensagem não poderá ser recuperada</div>
      </q-card-section>

      <q-card-actions vertical align="right">
        <q-btn
          flat
          color="negative"
          label="Excluir para todos"
          @click="onDialogOK(true)"
        />
        <q-btn flat color="primary" label="Cancelar" @click="onDialogCancel" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
