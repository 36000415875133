import { defineStore } from 'pinia'
import _ from 'lodash'

export const useAgencyStore = defineStore('agency-store', {
  state: () => ({
    agency: null as unknown as IPChatAuth.AgencyModel,
  }),
  getters: {
    name: (state) => state.agency?.name,
    logo: (state) => state.agency?.logo,
    domain: (state) => state.agency?.domain,
    hasAgency: (state) => !_.isEmpty(state.agency),
    platformTitle: (state) => state.agency?.platform_title,
    customizations: (state) => state.agency?.customization,
    getCustomByKey: (state) => {
      return (key: keyof IPChatAuth.AgencyCustomization) => {
        if (_.isEmpty(state.agency?.customization)) {
          return
        }

        return state.agency?.customization[key]
      }
    },
  },
  actions: {
    setAgency(props?: { agency: IPChatAuth.AgencyModel } | null) {
      if (!props) return

      const { agency } = props
      this.agency = agency
    },
  },
})
