export const messages = {
  FINISH_SERVICE: {
    SUCCESS: 'Atendimento finalizado com sucesso.',
    ERROR:
      'Houve um erro durante a finalização do atendimento, tente novamente!',
  },
  TRANSFER_CONTACT: {
    SUCCESS: 'Contato transferido com sucesso.',
    ERROR: 'Não foi possível transferir o contato, tente novamente.',
  },
  EDIT_CONTACT: {
    SUCCESS: 'Usuário editado com sucesso.',
    ERROR: 'Não foi possível editar o usuário, tente novamente.',
  },
  ERASE_MESSAGE: {
    ERROR: 'Não foi possível deletar a mensagem, tente novamente.',
  },
  ERASED_MESSAGE: {
    FROM_CONTACT: 'Mensagem apagada',
    FROM_ATTENDANT: 'Você apagou essa mensagem',
  },
  DELETE_CONTACT: {
    SUCCESS: 'Contato deletado com sucesso.',
    ERROR: 'Não foi possível deletar o contato, tente novamente.',
  },
}
