import { createId } from '@paralleldrive/cuid2'

export default class Conversation {
  readonly id = createId()
  readonly props!: IPChat.Conversation

  quotedMessage?: {
    message: IPChat.PartialMessage
  }

  totalMessages = 0

  quotedState = false
  showSearchMessages = false
  isSearch = false

  constructor(props: IPChat.Conversation) {
    this.props = props

    if (!this.props.messages?.length) {
      this.props.messages = []
    }

    if ('isSearch' in this.props.state && this.props.state.isSearch === true) {
      this.isSearch = true
    }
  }

  get totalLoadedMessages() {
    return this.messages.length
  }

  get status() {
    return this.props.state.state
  }

  get needToAssign() {
    return this.props.state.phase === 'attendant_assignment'
  }

  get isFinished() {
    return this.props.state.state === 'finished'
  }

  get isActive() {
    return this.props.state.state === 'active'
  }

  get contactName() {
    return this.props.contact.name
  }

  get contactUsername() {
    return this.props.contact.number
  }

  get contactId() {
    return this.props.contact.id
  }

  get avatar() {
    return this.props.contact?.avatar
  }

  get departmentId() {
    return this.props.state?.department_id
  }

  get instanceId() {
    return this.props.state.instance_id
  }

  get isGroup() {
    return this.props.state.is_group
  }

  get stateId() {
    return this.props.state.id
  }

  get protocol() {
    return this.props.state.protocol
  }

  get messages() {
    return this.props.messages
  }

  get hasMessages() {
    return this.props.messages.length ? true : false
  }

  get tags() {
    return this.props.contact.tags
  }

  get hasAttendant() {
    return this.props.state.attendant !== null && this.props.state.attendant?.id
      ? true
      : false
  }

  get attendantId() {
    return this.props.state?.attendant_id
  }

  get attendantName() {
    return this.props.state.attendant?.name.split(' ')[0]
  }

  get attendantFullName() {
    return this.props.state.attendant?.name
  }

  get hasTags() {
    return this.props.contact.tags && this.props.contact.tags.length
  }

  get lastMessagePreview() {
    return this.props.messages[this.props.messages.length - 1]
  }

  get lastMessageTime() {
    const lastMessage = this.props.messages[this.props.messages.length - 1]
    return lastMessage?.time || lastMessage?.created_at
  }

  get phase() {
    return this.props.state.phase
  }

  get pendingToRead() {
    return this.props.state?.pending_to_read
  }

  set pendingToRead(arg: boolean) {
    this.props.state.pending_to_read = arg
  }

  set state(state: IPChat.State) {
    this.props.state = state
  }

  set setIsSearch(is: boolean) {
    this.isSearch = is
  }

  getMessageByKey(key: string) {
    const messageMap = new Map(
      this.props.messages?.map((message) => [ message.key, message ])
    )
    return messageMap.get(key)
  }

  finish() {
    this.props.state.state = 'finished'
  }

  active() {
    this.props.state.state = 'active'
  }
}
